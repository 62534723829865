<template>
  <div
    class="question-container"
    v-bind:style="questionContainerStyle"
    @click="slotCardSelected"
    :class="{
      'empty-options': empty_options,
      'options-remaining': options_remaining,
      'empty-answer': empty_answer,
    }"
  >
    <div
      v-if="allows_crud && user.is_professor"
      class="question-move px-1 handle"
      :class="{ 'grabbable-cursor': allows_crud }"
      :style="questionDraggableStyle"
    >
      <b-icon class="move-icon" icon="arrows-expand" scale="1.3"></b-icon>
    </div>
    <div
      class="question-body max-width-print"
      :class="{ 'max-width': is_running && user.is_student }"
    >
      <div
        class="row-div"
        :class="{ 'margin-bottom-adjustment': !allows_crud }"
      >
        <div class="row-info-div">
          <div class="title-div ml-2 pb-2 pl-1">
            <div class="row py-2">
              <div class="pr-1 ml-2">
                <strong>{{ question.order }}</strong
                >.
              </div>
              <div
                v-if="allows_crud"
                class="m-0 text-cursor rich-text-content"
                v-html="question.title"
                :state="validateState('title')"
                state_feedback="Este campo es obligatorio."
                @click="
                  $bvModal.show(`edit-question-title-modal-${question.id}`)
                "
              ></div>
              <!-- <StyledTextArea
                v-if="allows_crud"
                class="m-0"
                v-model="$v.question.title.$model"
                placeholder="Escriba el enunciado aquí..."
                :state="validateState('title')"
                state_feedback="Este campo es obligatorio."
                @input="patchQuestionTitle"
              ></StyledTextArea> -->
              <template v-else>
                <div class="rich-text-content" v-html="question.title"></div>
              </template>
              <b-modal
                :id="`edit-question-title-modal-${question.id}`"
                title="Redactar enunciado de la pregunta."
                size="lg"
                hide-footer
              >
                <NewRichTextEditor
                  :Object="question"
                  :Text="question.title"
                  @save="patchQuestionTitle"
                  @close="
                    $bvModal.hide(`edit-question-title-modal-${question.id}`)
                  "
                ></NewRichTextEditor>
              </b-modal>
            </div>
          </div>
          <div v-if="question.question_type == 1">
            <div class="limit-words-section mb-0">
              <b-form-checkbox
                v-if="allows_crud"
                v-model="limited_words_amount"
                switch
              >
                Limitar longitud de respuesta.
              </b-form-checkbox>
            </div>
            <div class="d-flex mt-1" v-if="limited_words_amount">
              <b-form-group
                label="Limitar por:"
                label-cols="4"
                label-cols-sm="6"
                label-class="mt-1"
                class="w-50 mr-4"
              >
                <b-form-select
                  v-model="selected_answer_length_type"
                  :options="max_answer_length_types"
                  text-field="value"
                  value-field="id"
                  size="sm"
                  class="ml-0"
                  @input="patchQuestionWordAmountType"
                >
                </b-form-select>
              </b-form-group>
              <b-form-group
                :label="
                  selected_answer_length_type == 1
                    ? 'Establecer límite de palabras:'
                    : 'Establecer límite de caracteres:'
                "
                label-cols="4"
                label-cols-sm="8"
                label-class="px-0"
                class="mt-1 ml-3"
              >
                <b-form-input
                  type="number"
                  class="max-answer-length"
                  size="sm"
                  v-model.number="question.max_answer_length"
                  @change="patchQuestionWordAmount"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
        </div>

        <div class="footer-buttons-div">
          <div class="image-div col-div mr-1">
            <b-form-checkbox
              v-if="allows_crud"
              v-model="show_description"
              switch
              >Descripción</b-form-checkbox
            >
          </div>
          <!-- <div class="type-div">
            <strong>{{ questionType ? questionType.value : "-" }}</strong>
          </div> -->
          <div class="image-div col-div">
            <button
              v-if="allows_crud"
              v-b-tooltip.bottom.noninteractive.v-secondary
              class="action-button mb-1 mr-1 edit-btn"
              title="Insertar Imagen"
              @click="$bvModal.show(`modal-select-image-${Question.id}`)"
            >
              <b-icon-images></b-icon-images>
            </button>
            <button
              v-if="allows_crud"
              v-b-tooltip.bottom.noninteractive.v-secondary
              class="action-button mb-1 mx-1 edit-btn"
              :title="
                $getVisibleNames(
                  'manual.criterio_de_evaluacion',
                  false,
                  'Criterios de Evaluación'
                )
              "
              @click="$bvModal.show(`modal-select-criterias-${Question.id}`)"
            >
              <b-icon-list-check></b-icon-list-check>
            </button>
            <button
              v-if="allows_crud"
              v-b-tooltip.bottom.noninteractive.v-secondary="{ offset: 40 }"
              class="action-button mb-1 ml-1 edit-btn"
              title="Editar Pregunta"
              @click="$bvModal.show(`modal-edit-question-${Question.id}`)"
            >
              <b-icon-pencil-square></b-icon-pencil-square>
            </button>
          </div>
          <b-modal
            :id="`modal-edit-question-${Question.id}`"
            title="Editar Pregunta"
            size="xl"
            hide-footer
          >
            <QuestionForm
              :Question="Question"
              :question_test_id="Question.id"
              :evaluation_id="evaluation_id"
              @updated="slotUpdatedQuestion"
              @questionScoreUpdated="slotQuestionScoreUpdated"
            ></QuestionForm>
          </b-modal>
        </div>
      </div>
      <hr />
      <ImagesContainer
        class="mt-1"
        :Images="topQuestionImages"
        :allows_crud="allows_crud"
        @deleted="slotImageDeleted"
      ></ImagesContainer>

      <!-- <div v-if="question.images.length > 0" class="image-list-div" v-bind:style="imageAlignClass">
        <template v-for="image_id in question.images">
          <ImageEditor :image_id="image_id" :key="image_id" @align="slotImageAlign"></ImageEditor>
        </template>
      </div> -->
      <div v-if="show_description && allows_crud" class="content-div mt-2">
        <strong>Descripción</strong>
        <div
          v-if="question.description != ''"
          :id="`question-description-${question.id}`"
          class="m-0 p-1 question-description rich-text-content"
          v-html="question.description"
          :state="validateState('description')"
          state_feedback="Este campo es obligatorio."
          v-b-tooltip.v-secondary.noninteractive="'Editar descripción'"
          @click="
            $bvModal.show(`edit-question-description-modal-${question.id}`)
          "
        ></div>
        <div v-else class="align-center">
          <button-edit
            class="add-description"
            v-b-tooltip.v-secondary.noninteractive="'Redactar descripción'"
            @click="
              $bvModal.show(`edit-question-description-modal-${question.id}`)
            "
          >
            <b-icon icon="plus" class="add-description-icon"></b-icon>
          </button-edit>
        </div>
        <!-- <StyledTextArea
          v-model="$v.question.description.$model"
          placeholder="Escriba la descripción aquí..."
          :state="validateState('description') || true"
          @input="patchQuestionDescription"
        ></StyledTextArea> -->
        <b-modal
          :id="`edit-question-description-modal-${question.id}`"
          title="Redactar descripción de la pregunta."
          size="lg"
          hide-footer
        >
          <NewRichTextEditor
            :Object="question"
            :Text="question.description"
            @save="patchQuestionDescription"
            @close="
              $bvModal.hide(`edit-question-description-modal-${question.id}`)
            "
          ></NewRichTextEditor>
        </b-modal>
      </div>
      <div v-if="!allows_crud && question.description.length > 0">
        <p class="mb-1"><strong>Descripción</strong></p>
        <div class="rich-text-content" v-html="question.description"></div>
      </div>

      <slot></slot>

      <ImagesContainer
        class="mb-1"
        :Images="bottomQuestionImages"
        :allows_crud="allows_crud"
        @deleted="slotImageDeleted"
      ></ImagesContainer>

      <hr />
      <template v-if="provided_answer && allows_crud">
        <div class="provided_answer my-2">
          <div class="guide-label align-middle">Pauta</div>
          <StyledTextArea
            v-model="question.comment"
            placeholder="Escriba la pauta aquí..."
            @input="patchQuestionFeedback"
          ></StyledTextArea>
        </div>
      </template>
      <!-- <hr /> -->
      <div class="row-div">
        <div
          class="row-info-div pl-2"
          :class="{ 'footer-row-info-div': !allows_crud }"
        >
          <div class="score-div" :class="{ 'hidden-arrows': !allows_crud }">
            Puntaje
            <span v-if="answer_score && !is_running"
              >Obtenido: {{ answer_score }} /
            </span>
            <StyledInputNumber
              v-model="question.score"
              style="width: 60px"
              class="question-score-input"
              :class="{ question_score_error: question_score_error }"
              :min="0"
              :state="questionTest.score == maxQuestionScore || !allows_crud"
              v-b-tooltip.v-secondary.top.noninteractive="
                `${
                  questionTest.score != maxQuestionScore
                    ? 'El puntaje asignado a la pregunta no coincide con el puntaje máximo del Instrumento.'
                    : ''
                }`
              "
              @input="patchQuestionScore"
              :disabled="!allows_crud"
            ></StyledInputNumber>
            <!-- :state="
                (questionTest.score == maxQuestionScore &&
                  maxScoreValidator == question.score) ||
                !allows_crud
              "-->
            <!-- v-b-tooltip.v-secondary.top.noninteractive="
                `${
                  questionTest.score != maxQuestionScore
                    ? 'El puntaje asignado a la pregunta no coincide con el puntaje máximo del Instrumento '
                    : ''
                } ${
                  questionTest.score != maxQuestionScore &&
                  maxScoreValidator != question.score
                    ? 'y '
                    : ''
                } ${
                  maxScoreValidator != question.score
                    ? 'El puntaje asignado a la pregunta no coincide con el puntaje máximo estimado: ' +
                      maxScoreValidator
                    : ''
                }` -->
          </div>
          <div class="question-type-info noprint" v-if="user.is_professor">
            Tipo<span class="long-text-info"> de Pregunta</span>:
            {{ questionType ? questionType.value : "" }}
          </div>
        </div>
        <div class="footer-buttons-div">
          <div class="image-div col-div mr-2 mt-2">
            <b-form-checkbox v-if="allows_crud" v-model="provided_answer" switch
              >Pauta</b-form-checkbox
            >
          </div>
          <!-- <div v-if="isFormValid" class="image-div col-div">
            <button v-b-tooltip.bottom.noninteractive class="rounded-button" title="Guardar">
              <b-icon-file-post @click="save"></b-icon-file-post>
            </button>
          </div>-->
          <div
            v-if="!isNaN(question.id) && allows_crud"
            class="image-div col-div"
          >
            <button
              v-b-tooltip.bottom.noninteractive.v-secondary
              class="action-button mr-1 mt-1 edit-btn"
              title="Duplicar Pregunta"
              @click="duplicateQuestion(question)"
            >
              <b-icon-files></b-icon-files>
            </button>
          </div>
          <div
            v-if="!isNaN(question.id) && allows_crud"
            class="image-div col-div"
          >
            <button
              v-b-tooltip.bottom.noninteractive.v-secondary
              class="action-button mt-1 delete-btn"
              title="Eliminar Pregunta"
              @click="askForDeleteQuestion"
            >
              <b-icon-trash></b-icon-trash>
            </button>
          </div>
        </div>
      </div>
      <div v-if="empty_options" class="empty-options-alert mb-1">
        <b-icon icon="exclamation-circle" class="mr-1"></b-icon>
        Debe seleccionar al menos una opción.
      </div>
      <div v-else-if="options_remaining" class="options-remaining-alert mb-1">
        <b-icon icon="exclamation-circle" class="mr-1"></b-icon>
        Debe seleccionar {{ how_many_selected }} opciones.
      </div>
      <div v-else-if="empty_answer" class="empty-answer-alert mb-1">
        <b-icon icon="exclamation-circle" class="mr-1"></b-icon>
        Debe escribir una respuesta.
      </div>
    </div>
    <div
      v-if="!(is_running && user.is_student)"
      class="evaluation-criteria col noprint no-width-print"
      :class="{ 'no-width': is_running && user.is_student }"
    >
      <section class="evaluation-criteria-header mb-2">
        {{
          $getVisibleNames(
            "manual.criterio_de_evaluacion",
            true,
            "Criterios de Evaluación"
          )
        }}
      </section>
      <template
        v-if="
          question.evaluation_criterias_micro.length > 0 ||
          question.evaluation_criterias_macro.length > 0
        "
      >
        <EvaluationCriterias
          :key_id="question.id"
          :evaluation_id="evaluation_id"
          :ECMacro="question.evaluation_criterias_macro"
          :ECMicro="question.evaluation_criterias_micro"
          :length="2"
        ></EvaluationCriterias>
      </template>
      <template v-else>
        <div class="empty-evaluation-criterias">
          <span
            class="empty-evaluation-criterias-text"
            v-b-tooltip.v-secondary.noninteractive="
              'No se incorporará su resultado al cálculo de logros.'
            "
          >
            Esta pregunta no tiene asociado un
            {{
              $getVisibleNames(
                "manual.criterio_de_evaluacion",
                false,
                "Criterio de Evaluación"
              ).toUpperCase()
            }}.
          </span>
        </div>
      </template>
    </div>

    <!-- Modals -->
    <b-modal
      :id="`modal-select-image-${question.id}`"
      title="Seleccionar Imagen"
      hide-footer
    >
      <ImageSelector @created="slotCreatedImage"></ImageSelector>
    </b-modal>
    <b-modal
      :id="`modal-select-criterias-${question.id}`"
      :title="`Seleccionar ${$getVisibleNames(
        'manual.criterio_de_evaluacion',
        true,
        'Criterios de Evaluación'
      )}`"
      size="xl"
      hide-footer
    >
      <b-form-group
        :label="`${$getVisibleNames(
          'teaching.evaluationcriteriamacro',
          true,
          'Criterios de Evaluación Macro'
        )}:`"
        label-for="select-evaluation-criterias-macro"
        label-cols="0"
        label-cols-sm="3"
        v-if="institution && institution.show_evaluation_criteria_macro"
      >
        <template v-if="evaluationCriteriasMacrosFilter.length > 0">
          <b-form-select
            id="select-evaluation-criterias-macro"
            v-model="selected_evaluation_criteria_macro"
            multiple
            size="sm"
            class="select-form"
          >
            <b-form-select-option
              class="select-option-macro"
              v-for="item in evaluationCriteriasMacrosFilter"
              :key="item.id"
              :value="item.id"
            >
              {{ item.order }}. {{ item.full_sentence }}
            </b-form-select-option>
          </b-form-select></template
        >
        <template v-else>
          <span
            v-b-tooltip.v-secondary.noninteractive="
              `La ${$getVisibleNames(
                'mesh.egressprofilematter',
                false,
                'Asignatura'
              )} no cuenta con ${$getVisibleNames(
                'teaching.evaluationcriteriamacro',
                true,
                'Criterios de Evaluación Macro'
              )}`
            "
          >
            N/A
          </span></template
        >
      </b-form-group>
      <b-form-group
        :label="`${$getVisibleNames(
          'teaching.evaluationcriteriamicro',
          true,
          'Criterios de Evaluación Micro'
        )}:`"
        label-for="select-evaluation-criterias-micro"
        label-cols="0"
        label-cols-sm="3"
      >
        <template v-if="evaluationCriteriasMicrosFilter.length > 0">
          <b-form-select
            v-model="selected_evaluation_criteria_micro"
            :select-size="
              evaluationCriteriasMicrosFilter.length > 6
                ? 6
                : evaluationCriteriasMicrosFilter.length + 1
            "
            size="sm"
            multiple
            class="select-form"
          >
            <b-form-select-option-group
              v-for="item in filterECMicro"
              :key="item.id"
              :label="item.name"
            >
              <b-form-select-option
                class="select-option-micro"
                v-for="ecm in item.options"
                :value="ecm.id"
                :key="ecm.id"
                :title="ecm.name"
              >
                {{ item.order }}.{{ ecm.order }} {{ ecm.full_sentence }}
              </b-form-select-option>
            </b-form-select-option-group>
          </b-form-select>
        </template>
        <template v-else>
          <span
            v-b-tooltip.v-secondary.noninteractive="
              `La ${$getVisibleNames(
                'mesh.egressprofilematter',
                false,
                'Asignatura'
              )} no cuenta con ${$getVisibleNames(
                'teaching.evaluationcriteriamicro',
                true,
                'Criterios de Evaluación Micro'
              )}`
            "
          >
            N/A
          </span></template
        >
      </b-form-group>
      <div class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="updateCriterias"
          >Guardar</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId, toast } from "@/utils/utils";
import { required, minValue } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  name: "QuestionCard",
  mixins: [validationMixin],
  components: {
    ImageSelector: () => import("@/components/reusable/ImageSelector"),
    StyledTextArea: () => import("@/components/reusable/StyledTextArea"),
    StyledInputNumber: () => import("@/components/reusable/StyledInputNumber"),
    ImagesContainer: () => import("@/components/reusable/ImagesContainer"),
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
    EvaluationCriterias: () => import("../EvaluationCriterias.vue"),
    QuestionForm: () => import("../Test/QuestionForm.vue"),
  },
  props: {
    Question: {
      type: Object,
      required: true,
    },
    is_selected: {
      type: Boolean,
      default: false,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    is_running: {
      type: Boolean,
      default: false,
    },
    answer_score: {
      type: Number,
    },
    evaluation_id: {
      type: Number,
    },
    how_many_selected: {
      type: Number,
    },
    empty_options: {
      type: Boolean,
      default: false,
    },
    options_remaining: {
      type: Boolean,
      default: false,
    },
    question_score_error: {
      type: Boolean,
      default: false,
    },
    empty_answer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      question: {
        id: this.Question ? this.Question.id : generateUniqueId(),
        question_test: this.Question.question_test,
        title: this.Question ? this.Question.title : "",
        description: this.Question ? this.Question.description : "",
        comment: this.Question ? this.Question.comment : "",
        score: this.Question ? this.Question.score : 0,
        order: this.Question ? this.Question.order : null,
        test: this.Question.test,
        question_type: this.Question.question_type,
        evaluation_criterias_macro: this.Question
          ? this.Question.evaluation_criterias_macro
          : [],
        evaluation_criterias_micro: this.Question
          ? this.Question.evaluation_criterias_micro
          : [],
        cognitive_level: this.Question.cognitive_level,
        how_many_selected: this.Question.how_many_selected,
        max_answer_length: this.Question.max_answer_length,
        max_answer_length_type: this.Question
          ? this.Question.max_answer_length_type
          : this.selected_answer_length_type,
        automated_score_type: this.Question.automated_score_type,
        contents: this.Question ? this.Question.contents : [],
        images: this.Question ? this.Question.images : [],
        score_type: this.Question ? this.Question.score_type : 0,
        evaluation: this.Question.evaluation,
      },
      selected_evaluation_criteria_macro: this.Question
        ? this.Question.evaluation_criterias_macro
        : [],
      selected_evaluation_criteria_micro: this.Question
        ? this.Question.evaluation_criterias_micro
        : [],
      temp_options: [],
      provided_answer: false,
      show_description: false,
      limited_words_amount: false,
      imageAlignClass: {
        justifyContent: "center",
      },
      selected_answer_length_type: this.Question
        ? this.Question.max_answer_length_type
        : 1,
      max_answer_length_types: [],
    };
  },
  validations() {
    return {
      question: {
        title: { required },
        description: {},
        comment: {},
        score: { required, minValue: minValue(1) },
      },
    };
  },
  computed: {
    ...mapGetters({
      questionTypes: names.QUESTION_TYPES,
      scoreTypes: names.SCORE_TYPES,
      questions: names.QUESTIONS,
      evaluationInstruments: names.EVALUATION_INSTRUMENTS,
      images: names.IMAGES,
      questionTests: names.QUESTION_TESTS,
      evaluations: names.EVALUATIONS,
      evaluationCriteriasMacros: names.EVALUATION_CRITERIAS_MACROS,
      evaluationCriteriasMicros: names.EVALUATION_CRITERIAS,
      section_evaluations: names.SECTION_EVALUATIONS,
      studyUnits: names.STUDY_UNITS,
      allQuestionOptions: names.MULTIPLE_CHOICE_QUESTION_OPTIONS,
      user: "getUser",
      institution: "getInstitution",
    }),
    questionTest() {
      return this.questionTests.find(
        (x) => x.id == this.question.question_test
      );
    },
    maxScoreValidator() {
      let question = this.questions.find((x) => x.id == this.question.id);
      if (!question) return 0;
      if (question.question_type == 2 && question.how_many_selected) {
        let optionsMaxScores = [];
        let MaxCalculatedScore = 0;
        let allOptions = this.allQuestionOptions.filter(
          (x) => x.question == question.id
        );
        allOptions.forEach((x) => {
          optionsMaxScores.push(x.score);
        });
        optionsMaxScores.sort(function (a, b) {
          return b - a;
        });
        switch (question.automated_score_type) {
          case 1: {
            for (
              let index = 0;
              index < parseInt(question.how_many_selected);
              index++
            ) {
              if (optionsMaxScores[index] > 0) {
                MaxCalculatedScore += optionsMaxScores[index];
              }
            }
            return MaxCalculatedScore;
          }
          case 2:
          case 3:
          case 4: {
            MaxCalculatedScore = optionsMaxScores[0];
            return MaxCalculatedScore;
          }
        }
        return MaxCalculatedScore;
      } else return question.score;
    },
    maxQuestionScore() {
      let score = 0;
      this.questions.forEach((x) => {
        if (x.question_test == this.question.question_test) {
          score += x.score;
        }
      });
      return score;
    },
    evaluation() {
      return this.evaluation.find((x) => x.id == this.question.test);
    },
    questionType() {
      return this.questionTypes.find(
        (x) => x.id == this.question.question_type
      );
    },
    isFormValid() {
      this.$v.question.$touch();
      return !this.$v.question.$anyError;
    },
    evaluationCriteriasMacrosFilter() {
      let list = [];
      if (!this.filter_section_evaluation) return [];
      this.filter_section_evaluation.forEach((x) => {
        x.evaluation_criterias_macro.forEach((ra) => {
          list.push(ra);
        });
      });
      const unique = [...new Set(list)];
      return this.evaluationCriteriasMacros
        .filter((x) => unique.includes(x.id))
        .sort(function (a, b) {
          if (a.matter > b.matter) return 1;
          if (a.matter < b.matter) return -1;
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    evaluationCriteriasMicrosFilter() {
      let list = [];
      if (!this.filter_section_evaluation) return [];
      this.filter_section_evaluation.forEach((x) => {
        x.evaluation_criterias_micro.forEach((ra) => {
          list.push(ra);
        });
      });
      const unique = [...new Set(list)];
      return this.evaluationCriteriasMicros
        .filter((x) => unique.includes(x.id))
        .sort(function (a, b) {
          if (a.study_unit > b.study_unit) return 1;
          if (a.study_unit < b.study_unit) return -1;
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    filterECMicro() {
      let list = [];
      this.studyUnits.forEach((study_unit) => {
        const evaluation_criterias =
          this.evaluationCriteriasMicrosFilter.filter(
            (x) => x.study_unit == study_unit.id
          );
        if (evaluation_criterias.length > 0)
          list.push({
            id: study_unit.id,
            name: String("N°" + study_unit.order + ": " + study_unit.name),
            order: study_unit.order,
            options: this.evaluationCriteriasMicrosFilter
              .filter((x) => x.study_unit == study_unit.id)
              .sort(function (a, b) {
                if (a.order > b.order) return 1;
                if (a.order < b.order) return -1;
                return a.full_sentence
                  .toLowerCase()
                  .localeCompare(b.full_sentence.toLowerCase());
              }),
          });
      });
      return list;
    },
    filter_section_evaluation() {
      return this.section_evaluations.filter(
        (x) => x.evaluation == this.evaluation_id
      );
    },
    questionContainerStyle() {
      return {
        backgroundColor:
          this.is_selected && !this.allows_crud
            ? "var(--kl-menu-color)"
            : "white",
      };
    },
    questionDraggableStyle() {
      return {
        backgroundColor:
          this.is_selected && this.allows_crud
            ? "var(--kl-menu-color)"
            : "var(--primary-color)",
        color:
          this.is_selected && this.allows_crud
            ? "white"
            : "var(--kl-menu-color)",
      };
    },
    questionImages() {
      let list = [];
      this.question.images.forEach((element) => {
        const instance = this.images.find((x) => x.id == element);
        if (instance) list.push(instance);
      });
      return list;
    },
    topQuestionImages() {
      return this.questionImages.filter((x) => x.vertical_align == 1);
    },
    bottomQuestionImages() {
      return this.questionImages.filter((x) => x.vertical_align == 2);
    },
  },
  methods: {
    validateState(key) {
      const { $dirty, $error } = this.$v.question[key];
      return $dirty ? !$error : null;
    },
    slotImageAlign(align) {
      this.imageAlignClass.justifyContent = align;
    },
    // save() {
    //   // this.$v.question.$touch();
    //   // if (this.$v.question.$anyError) {
    //   //   return;
    //   // }
    //   if (isNaN(this.question.id)) this.createSimpleQuestion();
    //   else this.updateSimpleQuestion();
    // },
    // createSimpleQuestion() {
    //   this.$store
    //     .dispatch(names.POST_QUESTION, this.question)
    //     .then((response) => {
    //       this.question = response;
    //       this.$emit("created", this.question);
    //       toast("Pregunta guardada.");
    //     });
    // },
    // updateSimpleQuestion() {
    //   this.$store
    //     .dispatch(names.UPDATE_QUESTION, this.question)
    //     .then((response) => {
    //       this.question = response;
    //       this.$emit("updated", this.question);
    //       toast("Pregunta actualizada.");
    //     });
    // },
    updateCriterias() {
      const payload = {
        question_id: this.question.id,
        item: {
          evaluation_criterias_macro: this.selected_evaluation_criteria_macro,
          evaluation_criterias_micro: this.selected_evaluation_criteria_micro,
        },
      };
      this.$store.dispatch(names.PATCH_QUESTION, payload).then((response) => {
        if (response) {
          (this.question.evaluation_criterias_macro =
            response.evaluation_criterias_macro),
            (this.question.evaluation_criterias_micro =
              response.evaluation_criterias_micro),
            this.$bvModal.hide(`modal-select-criterias-${this.question.id}`);
        }
      });
    },
    duplicateQuestion(question) {
      if (question.question_type != 2) {
        this.$store.dispatch(names.POST_QUESTION, question).then((response) => {
          question = response;
          this.$emit("questionCopied", question);
          toast("Pregunta copiada.");
        });
      } else if (question.question_type == 2) {
        if (this.Question.how_many_selected)
          question.how_many_selected = this.Question.how_many_selected;
        else this.question.how_many_selected = 1;
        this.$store
          .dispatch(names.POST_MULTIPLE_CHOICE_QUESTION, question)
          .then((response) => {
            this.$store
              .dispatch(
                names.FETCH_MULTIPLE_CHOICE_QUESTION_OPTIONS,
                question.id
              )
              .then((options) => {
                options.forEach((x) => {
                  const payload = {
                    question: response.id,
                    text: x.text,
                    order: x.order,
                    comment: x.comment,
                    score: x.score,
                  };
                  this.$store.dispatch(
                    names.POST_MULTIPLE_CHOICE_QUESTION_OPTION,
                    payload
                  );
                });
              });
            this.$emit("questionCopied", response);
            toast("Pregunta copiada.");
          });
      }
    },
    askForDeleteQuestion() {
      this.$swal({
        title: "¿Está seguro de que desea eliminar la Pregunta?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$emit("deletedQuestion", this.question);
          this.deleteQuestion();
        }
      });
    },
    deleteQuestion() {
      this.$store.dispatch(names.DELETE_QUESTION, this.question.id).then(() => {
        // let question_score = this.question.score;
        // this.$emit("deletedQuestion", this.question);
        toast("Pregunta eliminada.");
      });
    },
    slotDeleteTempOption(option_id) {
      let index = this.temp_options.findIndex((x) => x.id == option_id);
      if (index != -1) this.temp_options.splice(index, 1);
    },
    slotCreatedImage(image) {
      this.question.images.push(image.id);
      this.$store
        .dispatch(names.UPDATE_QUESTION, this.question)
        .then((response) => {
          this.question = response;
        });
      this.$bvModal.hide(`modal-select-image-${this.question.id}`);
    },
    slotCardSelected() {
      this.$emit("selected", this.Question.id);
    },
    patchQuestion(item) {
      this.$v.question.$touch();
      if (this.$v.question.$anyError) {
        toast(
          "No se pudieron guardar los cambios. Hay errores que debe corregir."
        );
        return;
      }
      const payload = {
        question_id: this.question.id,
        item: item,
      };
      if (this.question.question_type == 2) {
        this.$store
          .dispatch(names.PATCH_MULTIPLE_CHOICE_QUESTION, payload)
          .then((response) => {
            if (response) {
              this.question = response;
              this.$emit("questionPatched", this.question);
              toast("Pregunta actualizada.");
            }
          });
      } else {
        this.$store.dispatch(names.PATCH_QUESTION, payload).then((response) => {
          if (response) {
            this.question = response;
            toast("Pregunta actualizada.");
            this.$emit("questionPatched", this.question);
          }
        });
      }
    },
    slotUpdatedQuestion(response) {
      this.question = response;
      this.$bvModal.hide(`modal-edit-question-${this.question.id}`);
    },
    patchQuestionTitle(question, text) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.patchQuestion({
          title: text,
        });
      }, 500);
    },
    patchQuestionDescription(question, text) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.patchQuestion({
          description: text,
        });
      }, 500);
    },
    patchQuestionWordAmount() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.patchQuestion({
          max_answer_length: this.question.max_answer_length,
        });
      }, 1000);
    },
    patchQuestionScore() {
      this.question.score = parseInt(this.question.score);
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.patchQuestion({
          score: this.question.score,
        });
      }, 1000);
    },
    patchQuestionWeighing() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.patchQuestion({
          weighing: this.question.weighing,
        });
      }, 1000);
    },
    patchQuestionFeedback() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.patchQuestion({
          comment: this.question.comment,
        });
      }, 1000);
    },
    patchQuestionScoreType() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.patchQuestion({
          score_type: this.question.score_type,
        });
      }, 2000);
    },
    patchQuestionWordAmountType() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.patchQuestion({
          max_answer_length_type: this.selected_answer_length_type,
        });
      }, 2000);
    },
    fetchMaxAnswerLengthTypes() {
      this.$restful
        .Get(`/evaluation/max-answer-length-type/`)
        .then((response) => {
          this.max_answer_length_types = response;
        });
    },
    slotQuestionScoreUpdated(question_score) {
      this.$emit("questionScoreUpdated", question_score);
    },
    slotImageDeleted(image_id) {
      const image_index = this.question.images.findIndex(
        (element) => element == image_id
      );
      if (image_index != -1) {
        this.question.images.splice(image_index, 1);
      }
    },
  },
  created() {
    this.question.images.forEach((element) => {
      this.$store.dispatch(names.FETCH_IMAGE, element);
    });
    this.fetchMaxAnswerLengthTypes();
  },
  mounted() {
    this.$v.question.$touch();
  },
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.question-container {
  display: flex;
  border-radius: 7px;
  margin-top: 5px;
  margin-bottom: 15px;
  box-shadow: 0px 2px 8px -3px var(--secondary-color);
  border: 1px solid #c9c9c9;
  /* border: 1px solid rgba(153, 201, 216, 0); border transition */
  transition: all 0.5s ease-out;
}
.empty-options,
.options-remaining,
.empty-answer {
  border: 1px solid #dc3545 !important;
}
.empty-options-alert,
.options-remaining-alert,
.empty-answer-alert {
  color: #dc3545;
  margin-bottom: -1%;
}
.question_score_error {
  border: 1px solid #dc3545 !important;
}
.question-inner {
  display: flex;
}
.max-width {
  width: 100% !important;
  border-radius: 5px;
}
.no-width {
  width: 0% !important;
  visibility: hidden;
}
@media print {
  .max-width-print {
    width: 100% !important;
    border-radius: 5px;
  }
  .no-width-print {
    width: 0% !important;
    visibility: hidden;
  }
}
.question-body {
  width: 65%;
  background-color: white;
  margin-left: 5px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.header-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.title-div {
  width: 75%;
  text-align: left;
}
.content-div {
  margin: 0.1em 0.5em;
  display: flex;
  flex-direction: column;
  align-items: initial;
}
.row-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.margin-bottom-adjustment {
  margin-bottom: -1%;
}
.row-info-div {
  width: 70%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.footer-row-info-div {
  width: 100% !important;
  margin-left: 0.55em;
  padding-left: 0 !important;
}
.footer-buttons-div {
  width: 29%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.type-div {
  width: auto;
  align-self: center;
  text-align: left;
  padding-right: 10px;
}
.image-div {
  margin: 0.1em;
}
.action-button {
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  background-color: var(--secondary-color);
  transition: 0.3s all;
}
.action-button:hover {
  background-color: #6f6f6f;
  color: #ebebeb !important;
  transition: 0.3s all;
  transform: scale(1.15);
}
.question-description >>> p {
  margin-bottom: 0;
}
.question-description {
  padding: 0;
  cursor: text;
}
.question-description:hover {
  background: #eee;
}
.add-description {
  border-radius: 30px;
  width: fit-content;
  padding: 0 0.15em;
}
.add-description-icon {
  transform: translateY(7%);
}
.image-list-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0.1em 0.5em;
}
.provided_answer {
  margin: 0px 0.5em;
  display: flex;
}
.guide-label {
  padding-top: 0.3em;
}
a {
  text-align: left;
  width: min-content;
  min-width: 25%;
  vertical-align: middle;
}
hr {
  background-color: var(--secondary-color);
  height: 0.5px;
  margin: 0px 0px;
}
.vl {
  border-left: 1px solid var(--secondary-color);
  padding: 0px;
  margin: 0px;
}
.evaluation-criteria {
  padding: 0.3em;
  background: rgb(242, 242, 242);
  font-weight: bold;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.evaluation-criteria-header {
  width: 100%;
  background: var(--primary-color);
  border-top-right-radius: 4px;
  margin-bottom: 0.2em;
}
.text-cursor {
  cursor: text !important;
}
.question-type-info {
  margin-left: 1em;
  margin-top: 0.4em;
}
.question-score-input {
  margin-top: 0.1em;
  /* height: 2em; */
}
.question-move {
  vertical-align: middle;
  background: var(--primary-color);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.move-icon {
  /* transform: translate(-60%, 180%); */
  margin: 0;

  position: relative;
  top: 45%;
  cursor: grab;
}
.grabbable-cursor {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.grabbable-cursor:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.hidden-arrows >>> input[type="number"]::-webkit-inner-spin-button,
.hidden-arrows >>> input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.select-form {
  display: block !important;
}
.select-option-macro,
.select-option-micro {
  overflow-wrap: break-word;
  white-space: pre-wrap;
  padding: 0 0.25em;
  margin-left: 10px;
}
.select-option-micro {
  text-indent: -16px;
}
.max-answer-length {
  margin-top: -1%;
}
.limit-words-section {
  margin-right: auto !important;
  margin-left: 0 !important;
  text-align: left;
}
.empty-evaluation-criterias {
  margin-top: 1em;
  padding: 1em 2em;
}
.empty-evaluation-criterias-text {
  color: #dc3545 !important;
  font-weight: bold;
  font-size: 11pt;
  transition: 0.3s ease-in-out;
}
.edit-btn {
  background-color: var(--kl-primary-button-color) !important;
  color: var(--secondary-font-color);
}
.edit-btn:hover {
  background-color: var(--kl-primary-button-hover-color) !important;
}
.delete-btn {
  background-color: #dc3545 !important;
  color: var(--secondary-font-color);
}
.delete-btn:hover {
  background-color: #e45d6b !important;
}
@media (max-width: 800px) {
  .long-text-info {
    display: none;
  }
}
</style>